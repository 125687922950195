import React, { Fragment } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Bottles from "../components/imageComponents/bottles"
import mainStyles from "./indexStyles.module.scss"
import ColorDots from "../components/ColorDots/ColorDots"
import Button from "../components/Button/Button"
import HandWithPhone from "../components/imageComponents/handWithPhone"
import EmailSignup from "../components/EmailSignup/EmailSignup"
import Footer from "../components/Footer/Footer"
import AgeGate from "../components/AgeGate/AgeGate"

const About = () => {
  return (
    <Fragment>
      <AgeGate></AgeGate>
      <Layout>
        <section className={"centered"}>
          <h1 style={{ margin: "120px 0 40px 0" }}>
            Advancing Cannabis Medicine for YOU
          </h1>
          <p className={mainStyles.textBox} style={{ margin: "0 auto " }}>
            KindBio is a Missouri owned, grown and operated cultivator and
            manufacturer of medical marijuana. Our expert team is leading the
            country in molecular breeding technology to deliver the
            highest-quality medical marijuana that directly impacts your needs.
          </p>
          <ColorDots style={{ margin: "30px 0 0 0" }}></ColorDots>
        </section>
        <div
          className={[
            mainStyles.slide1,
            mainStyles.reverseCol,
            "parallaxMiddle",
          ].join(" ")}
          style={{ marginTop: "70px" }}
        >
          <div
            className={[
              mainStyles.slide1Child,
              mainStyles.responsiveAlign,
            ].join(" ")}
          >
            <div className={mainStyles.textBoxSm} style={{ margin: "0 auto" }}>
              <h1
                className={[
                  mainStyles.header1,
                  mainStyles.responsiveAlign,
                ].join(" ")}
              >
                Find Your True Strain
              </h1>
              <p className={"mt2"}>
                Soon, you will be able to join our proprietary Health Portal to
                discover your KindBio True Strain, and identify the personalized
                therapy to best suit your needs. Our wide array of certified
                provisions will be available in flower, vape, pre-roll, and
                tinctures and a bespoke line of premium edibles.
              </p>
              <Button
                onClick={() => {
                  navigate("/products")
                }}
              >
                VIEW PRODUCTS
              </Button>
            </div>
          </div>
          <div className={[mainStyles.bottleSection].join(" ")}>
            <div className={[mainStyles.bottleWrapper].join(" ")}>
              <Bottles></Bottles>
            </div>
          </div>
        </div>
        <div
          className={[
            mainStyles.slide,
            mainStyles.slide1,
            "parallaxMiddle",
          ].join(" ")}
          style={{ position: "relative" }}
        >
          <div
            className={[mainStyles.slide1leftWrapper, "parallaxMiddle"].join(
              " "
            )}
          >
            <div
              className={[mainStyles.handWithPhone, "parallaxChild"].join(" ")}
            >
              <HandWithPhone></HandWithPhone>
            </div>
            <div
              className={[mainStyles.lgImgWrapper, "parallaxChild"].join(" ")}
              style={{ opacity: 0 }}
            >
              <HandWithPhone></HandWithPhone>
            </div>
          </div>
          <div className={mainStyles.slide1rightWrapper}>
            <div className={mainStyles.textWrapper}>
              <h1
                className={[mainStyles.header1, "mb2", "responsiveAlign"].join(
                  " "
                )}
              >
                Connect with Kindness
              </h1>
              <p className={[mainStyles.subheader, "mt2"].join(" ")}>
                The KindBio Health Portal will allow you to keep a Dosing
                Journal, and provide the KindBio medical team with direct
                feedback. This trusted partnership results in a crowd-sourced
                cannabis development platform for the advancement of medical
                cannabis therapy. Coming soon!
              </p>
            </div>
          </div>
        </div>
        <section className={"centered"}>
          <h1 style={{ margin: "60px 0 40px 0" }}>Our Trusted Team</h1>
          <p style={{ margin: "50px auto ", maxWidth: "900px" }}>
            The KindBio team is comprised of accomplished professionals who have
            come together for the same cause&mdash;to provide patients with
            exceptional products that are personalized to their medical needs.
            <br></br>
            <br></br>We are a PhD biochemist, an Iraqi war army veteran, four
            cancer survivors, a Baptist minister, doctors, a molecular
            biologist, an international human rights observer, three award
            winning chefs, long-distance runners, three organic farmers, a
            trained opera singer, single mother, navy veteran, avid fly
            fisherman, and{" "}
            <span style={{ textDecoration: "underline" }}>you</span>. We invite
            you to become a part of our work in our crowd-sourced medical
            cannabis platform to make cannabis medicine better for all. We are
            in this together!
          </p>
          <h2 style={{ marginTop: "20px" }}>
            Together, we can move cannabis medicine forward.
          </h2>
        </section>
        <EmailSignup></EmailSignup>
        <Footer></Footer>
      </Layout>
    </Fragment>
  )
}

export default About
